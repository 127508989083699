var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login-client"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center align-items-center",attrs:{"id":"login-row"}},[_c('div',{staticClass:"col-md-6 card mt-40",attrs:{"id":"login-column card"}},[_c('div',{staticClass:"col-md-12 card-body",attrs:{"id":"login-box"}},[_c('form',{staticClass:"form",attrs:{"id":"login-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.client_slug != '')?_c('h3',{staticClass:"text-center text-info"},[_c('img',{staticStyle:{"width":"25%"},attrs:{"src":_vm.getServiceProviderData.image == null
                    ? _vm.getServiceProviderData.logo
                    : _vm.getServiceProviderData.image,"alt":"logo"}})]):_vm._e(),(_vm.client_slug != '')?_c('hr'):_vm._e(),(_vm.errorMsg != '')?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-dark text-uppercase",attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:_vm.errors['username'] && this.username == ''
                    ? 'border border-danger'
                    : '',attrs:{"type":"text"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),(_vm.errors['username'] && _vm.username == '')?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["username"])+" ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-dark text-uppercase",attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticStyle:{"position":"relative"}},[((_vm.password_type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:_vm.errors['password'] && this.password == ''
                      ? 'border border-danger'
                      : '',attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.password_type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:_vm.errors['password'] && this.password == ''
                      ? 'border border-danger'
                      : '',attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:_vm.errors['password'] && this.password == ''
                      ? 'border border-danger'
                      : '',attrs:{"type":_vm.password_type},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showPassword()}}},[_c('i',{class:_vm.showPasswordIcon
                        ? 'fa fa-eye text-info'
                        : 'fa fa-eye-slash',staticStyle:{"position":"absolute","right":"10px","top":"12px"}})])]),(_vm.errors['password'] && _vm.password == '')?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["password"])+" ")]):_vm._e()]),_c('div',{staticClass:"form-group",staticStyle:{"margin-bottom":"-30px"}},[_c('a',{on:{"click":function($event){return _vm.forgotPassword()}}},[_c('label',{staticClass:"text-info text-uppercase",attrs:{"for":"remember-me"}},[_vm._v("Forgot password ?")])])]),_c('button',{ref:"kt_login_signin_submit",staticClass:"btn btn-info btn-md mt-0",staticStyle:{"float":"right"}},[_vm._v(" Sign In ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }